import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import moment from "moment";
import { getData } from "@/services/axios";
import RowItem from "../RowItem";
import { mapState } from "vuex";
export default {
  name: "MessageHistories",
  components: {
    RowItem: RowItem
  },
  data: function data() {
    return {
      fiscalYear: moment().add(-3, "month").year(),
      filterOptions: {
        keywordFilter: "",
        dateFilter: "",
        datepickerShortcuts: [{
          label: "今日",
          value: "day",
          key: "Today"
        }, {
          label: "先週",
          value: "-week",
          key: "LastWeek"
        }, {
          label: "今週",
          value: "week",
          key: "ThisWeek"
        }, {
          label: "先月",
          value: "-month",
          key: "LastMonth"
        }, {
          label: "今月",
          value: "month",
          key: "ThisMonth"
        }, {
          label: "去年",
          value: "-year",
          key: "LastYear"
        }, {
          label: "今年",
          value: "year",
          key: "ThisYear"
        }]
      },
      isLoading: true,
      showModal: false,
      title: "",
      messages: [],
      filteredMessages: [],
      intervalId: undefined,
      listModified: moment().format("HH:mm:ss"),
      listUpdating: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["fiscalYears"])), {}, {
    numberOfStatuses: function numberOfStatuses() {
      return this.filteredMessages.reduce(function (p, v) {
        return p + v.counts + v.participant_counts;
      }, 0);
    },
    numberOfPublishedMessages: function numberOfPublishedMessages() {
      return this.filteredMessages.filter(function (v) {
        return v.status === "published";
      }).length;
    }
  }),
  created: function created() {
    var _this = this;

    this.setData();
    this.intervalId = setInterval(function () {
      if (!_this.listUpdating) {
        _this.setData();
      }
    }, 1000 * 60);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    isPastList: function isPastList(message) {
      return moment(message.message_scheduled_for).add(-3, "month").year() < moment().add(-3, "month").year();
    },
    setData: function setData() {
      var _this2 = this;

      this.listUpdating = true;
      getData(["reader/get_message_histories"]).then(function (data) {
        _this2.messages = data[0].items.map(function (v) {
          var _v$teacher$name, _v$teacher;

          v.teacher_name = (_v$teacher$name = (_v$teacher = v.teacher) === null || _v$teacher === void 0 ? void 0 : _v$teacher.name) !== null && _v$teacher$name !== void 0 ? _v$teacher$name : "";
          v.message_scheduled_for_date = moment(v.message_scheduled_for).format("YYYY年MM月DD日(ddd) HH:mm");
          return v;
        });
        _this2.filteredMessages = _this2.messages.filter(function (v) {
          return moment(v.message_scheduled_for) > moment(Number(_this2.fiscalYear) + "-04-01") && moment(v.message_scheduled_for) < moment(Number(_this2.fiscalYear) + 1 + "-04-01");
        });
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this2.isLoading = false;
        _this2.listUpdating = false;
        _this2.listModified = moment().format("HH:mm:ss");
      });
    },
    doFilter: function doFilter() {
      var _this3 = this;

      var messages = this.messages;
      var keyword = this.filterOptions.keywordFilter;
      var momentDateStart = this.filterOptions.dateFilter.start ? moment(this.filterOptions.dateFilter.start).startOf("day") : false;
      var momentDateEnd = this.filterOptions.dateFilter.end ? moment(this.filterOptions.dateFilter.end).startOf("day") : false;

      if (keyword) {
        messages = messages.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return String(v[k]).indexOf(keyword) > -1;
          });
        });
      }

      if (momentDateStart && !momentDateEnd) {
        messages = messages.filter(function (v) {
          return momentDateStart.isBefore(moment(v.message_scheduled_for).startOf("day"));
        });
      }

      if (momentDateEnd && !momentDateStart) {
        messages = messages.filter(function (v) {
          return momentDateEnd.isAfter(moment(v.message_scheduled_for).startOf("day"));
        });
      }

      if (momentDateStart && momentDateEnd) {
        if (momentDateStart.isSame(momentDateEnd)) {
          messages = messages.filter(function (v) {
            return momentDateStart.isSame(moment(v.message_scheduled_for).startOf("day"));
          });
        } else {
          messages = messages.filter(function (v) {
            return moment(v.message_scheduled_for).isBetween(momentDateStart, momentDateEnd, null, "[]");
          });
        }
      }

      messages = messages.filter(function (v) {
        return moment(v.message_scheduled_for) > moment(Number(_this3.fiscalYear) + "-04-01") && moment(v.message_scheduled_for) < moment(Number(_this3.fiscalYear) + 1 + "-04-01");
      });
      this.filteredMessages = messages;
    },
    handleMessageDate: function handleMessageDate(message) {
      switch (message.status) {
        case "published":
        case "sending":
        case "redial":
          return message.message_scheduled_for_date;

        default:
          return "";
      }
    },
    getMessageStatusJP: function getMessageStatusJP(message) {
      switch (message.status) {
        case "draft":
          return "下書き";

        case "published":
          return "送信完了";

        case "reserved":
          if (-60 <= moment().diff(message.message_scheduled_for, "seconds")) {
            return "送信準備中";
          } else {
            return "予約済";
          }

        case "sending":
          return "送信中";

        case "redial":
          return "リダイヤル中";

        case "reviewing":
          return "承認依頼中";

        default:
          return "不明";
      }
    }
  }
};