import _slicedToArray from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('table', {
    staticClass: "table"
  }, [_c(_setup.MonthlyTableHead, {
    attrs: {
      "month-data": _vm.monthData,
      "show-sub-head": _vm.classIndex === 0
    }
  }), _c('tbody', {
    staticClass: "table__body"
  }, [_c(_setup.MonthlySchoolDay, {
    attrs: {
      "days": _vm.monthData.length,
      "month-data": _vm.monthData
    }
  }), _c(_setup.MonthDailyEvent, {
    attrs: {
      "days": _vm.monthData.length,
      "month-data": _vm.monthData,
      "daily-events": _vm.dailyEvents,
      "classroom": _vm.classroom
    }
  }), _vm._l(_setup.students, function (student, index) {
    return _c(_setup.MonthlyStudentTable, {
      key: index,
      attrs: {
        "student": student,
        "index": index,
        "days": _vm.monthData.length,
        "month-data": _vm.monthData,
        "display-label": _vm.displayLabel
      },
      on: {
        "handleCommentEdit": _setup.handleCommentEdit
      }
    });
  }), _vm._l(_setup.dailySummary, function (_ref, i) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return _c(_setup.DailySummaryTable, {
      key: "daily-".concat(i),
      attrs: {
        "summary-key": key,
        "month-data": _vm.monthData,
        "summary-value": value
      }
    });
  })], 2)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };