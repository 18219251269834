import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { getData, createData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import sortableList from "@/components/common/SortableList";
import { downloadStudentsCSV } from "@/services/common";
import RowItem from "../RowItem";
import MessageModal from "../common/MessageModal.vue";
import { mapStores } from "pinia";
import { useGradeClassroomStore } from "@/stores/gradeClassroom";
export default {
  name: "ClassRoomsIndex",
  components: {
    RowItem: RowItem,
    LoadingMessage: LoadingMessage,
    sortableList: sortableList,
    MessageModal: MessageModal
  },
  data: function data() {
    return {
      selectedGrade: false,
      filteredGrades: [],
      showModal: false,
      classroomSortList: [],
      sortChangedItems: [],
      sortSaveComplete: false,
      isLoading: {
        sortData: true,
        classroomData: true
      },
      localModalSettings: {
        title: "",
        message: ""
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["grades", "studentAddress"])), mapStores(useGradeClassroomStore)),
  created: function created() {
    this.setData();
  },
  methods: {
    downloadStudentsCSV: downloadStudentsCSV,
    setData: function setData() {
      var _this = this;

      getData(["grades?with=classroom", "reader/get_classroom_with_students_count"]).then(function (data) {
        _this.$store.dispatch("commitGrade", data[0].items);

        _this.gradeClassroomStore.grades = data[0].items; // pinia使用のため重複管理を許容する

        _this.filteredGrades = data[0].items.filter(function (v) {
          return !v.fiscal_year;
        });
        data[1].items.forEach(function (v) {
          _this.filteredGrades = _this.filteredGrades.map(function (g) {
            g.classroom = g.classroom.map(function (c) {
              if (v.id === c.id) {
                c.studentCount = v.students_count;
              }

              return c;
            });
            g.classroom.sort(function (a, b) {
              // 複式学級は末尾
              if (a.classroom_grade.length > 1 || b.classroom_grade.length > 1 || a.index > b.index) return 1;

              if (a.index < b.index) {
                return -1;
              }

              return 0;
            });
            return g;
          });
        });
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading.classroomData = false;
      });
    },
    gradeFilter: function gradeFilter() {
      var grades = this.filteredGrades = this.grades;
      var grade_id = this.selectedGrade;
      this.filteredGrades = grade_id ? grades.filter(function (v) {
        return v.id === grade_id;
      }) : grades;
    },
    showModalSort: function showModalSort(grade_id) {
      var _this2 = this;

      this.sortChangedItems = [];
      this.sortSaveComplete = false;
      this.showModal = true;
      this.isLoading.sortData = true;
      getData("grades?with=classroom").then(function (data) {
        _this2.classroomSortList = data.items.filter(function (v) {
          return v.id === grade_id;
        })[0].classroom;
        _this2.isLoading.sortData = false;
      }).catch(function (error) {
        console.log(error);
      });
    },
    saveClassroomOrder: function saveClassroomOrder() {
      var _this3 = this;

      var classroomSortList = this.classroomSortList.map(function (v, i) {
        v.index = i + 1;
        return v;
      });
      createData("writer/write_classroom_index", {
        classrooms: classroomSortList
      }).then(function () {
        _this3.showModal = false;

        _this3.setData();

        _this3.localModalSettings = {
          title: "保存完了",
          message: "クラスの並び替えを保存しました"
        };
        _this3.sortSaveComplete = true;
      }).catch(function (error) {
        _this3.localModalSettings = {
          title: "保存失敗",
          message: "クラスの並び替えに失敗しました"
        };
        console.log(error);
      });
    },
    cancelClassroomOrder: function cancelClassroomOrder() {
      this.showModal = false;
    },
    handleCancel: function handleCancel() {
      this.showModal = false;
    },
    completeRefresh: function completeRefresh() {
      this.sortSaveComplete = false;
      this.$router.push({
        name: "classrooms"
      });
    }
  }
};