import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import emailValidator from "email-validator";
export default {
  store: store,
  name: "ParticipantCSVUpload",
  components: {
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      csv: [],
      error: [],
      warning: [],
      tableError: [],
      tableWarning: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false
    };
  },
  computed: _objectSpread({}, mapState(["me"])),
  created: function created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },
  methods: {
    downloadTemplateCSV: function downloadTemplateCSV() {
      var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["氏名", "氏名かな", "所属", "言語", "メールアドレス", "電話番号", "お知らせ方法", "メモ"]])], {
        type: "text/csv;charset=utf-8"
      }), "participant_template.csv");
    },
    setAttachment: function setAttachment(e) {
      var _this = this;

      var csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.warning = [];
      this.tableError = [];
      this.tableWarning = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      var reader = new FileReader();

      reader.onload = function (e) {
        var codes = new Uint8Array(e.target.result);
        var encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        var from = encoding === "UTF8" ? "UTF8" : "SJIS";
        var csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        var csv = Papa.parse(csvString);
        _this.csv = _this.csvDataCleaning(csv); //同名ファイルの再アップロードを検知するためにリセット

        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },
    csvDataCleaning: function csvDataCleaning(csv) {
      var _this2 = this;

      //エラーテーブルを初期化
      csv.data.forEach(function (v) {
        var row = [];
        v.forEach(function () {
          return row.push(false);
        });

        _this2.tableError.push(row);

        _this2.tableWarning.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(function (v) {
          return v.message;
        });
      }

      var data = [];
      csv.data.forEach(function (row, i) {
        if (i === 0) {
          if (row.join() !== "氏名,氏名かな,所属,言語,メールアドレス,電話番号,お知らせ方法,メモ") {
            _this2.error.push("見出し行が「氏名」「氏名かな」「所属」「言語」「メールアドレス」「電話番号」「お知らせ方法」「メモ」と異なります");

            _this2.tableError[i] = _this2.tableError[i].map(function () {
              return true;
            });
          }

          return data.push(row);
        }

        if (row.length !== 8) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            _this2.error.push(i + 1 + "行目の値が9列ではありません");

            _this2.tableError[i] = _this2.tableError[i].map(function () {
              return true;
            });
            _this2.tableWarning[i] = _this2.tableWarning[i].map(function () {
              return true;
            });
            return data.push(row);
          } else {
            return;
          }
        }

        row = row.map(function (cell, j) {
          cell = Encoding.toHankakuCase(cell).trim();
          var valid = true;

          if (j === 0) {
            if (!cell) {
              _this2.error.push(i + 1 + "行目の氏名を入力してください。");

              valid = false;
            } else if (!cell.match(/\s/)) {
              _this2.error.push(i + 1 + "行目の氏名の姓と名の間に半角スペースを入れてください。");

              valid = false;
            }

            if (cell) {
              if (cell.match(/\?/) || cell.match(/__/)) {
                _this2.warning.push(i + 1 + "行目の氏名に文字化けが含まれる可能性があります。");

                valid = false;
              }
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 1) {
            if (cell && !cell.match(/\s/)) {
              _this2.error.push(i + 1 + "行目の氏名かなの姓と名の間に半角スペースを入れてください。");

              valid = false;
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 2 && cell) {
            if (cell.match(/\?/) || cell.match(/__/)) {
              _this2.warning.push(i + 1 + "行目の所属に文字化けが含まれる可能性があります。");

              valid = false;
            }
          }

          if (j === 3 && cell && !(cell === "日本語" || cell === "英語" || cell === "スペイン語" || cell === "中国語")) {
            _this2.error.push(i + 1 + "行目の言語は「日本語」「英語」「スペイン語」「中国語」のいずれかを入力するか、空欄(日本語になります)としてください。");

            valid = false;
          }

          if (j === 4 && cell) {
            if (!emailValidator.validate(cell)) {
              _this2.error.push(i + 1 + "行目のメールアドレスの形式が正しくありません。");

              valid = false;
            }
          } else if (j === 4 && !cell && row[6] === "メール") {
            _this2.error.push(i + 1 + "行目のお知らせ方法に「メール」が指定されています。メールアドレスを入力してください。");

            valid = false;
          }

          if (j === 5 && cell) {
            if (!/^0/.test(cell)) {
              cell = "0" + cell;
            }

            var regex = new RegExp(/^[0-9]{10,11}$/);

            if (!regex.test(cell)) {
              _this2.error.push(i + 1 + "行目の電話番号を半角数字のみ、10桁あるいは11桁で入力してください。");

              valid = false;
            }

            if (data.find(function (v) {
              return v[5] === cell;
            })) {
              _this2.error.push(i + 1 + "行目の電話番号が重複しています");

              valid = false;
            }
          } else if (j === 5 && !cell && row[6] === "電話") {
            _this2.error.push(i + 1 + "行目のお知らせ方法に「電話」が指定されています。メールアドレスを入力してください。");

            valid = false;
          }

          if (j === 6) {
            if (!cell || cell && cell !== "電話" && cell !== "メール") {
              _this2.error.push(i + 1 + "行目のお知らせ方法は「メール」か「電話」のいずれかを入力してください。");

              valid = false;
            }
          }

          if (j === 8 && cell && _this2.error.length === 0) {
            if (cell.match(/\?/) || cell.match(/__/)) {
              _this2.warning.push(i + 1 + "行目のメモに文字化けが含まれる可能性があります。");

              valid = false;
            }
          }

          if (!valid) _this2.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return data;
    },
    saveData: function saveData() {
      var _this3 = this;

      this.isLoading = true;
      var localeMap = {
        日本語: "ja-JP",
        英語: "en-US",
        スペイン語: "es-US",
        中国語: "zh-CN"
      };
      var normalReceiveMethodMap = {
        電話: "phone"
      }; //ヘッダー行の除去

      this.csv.shift();
      var participants = this.csv.map(function (v) {
        return {
          name: v[0],
          ruby: v[1],
          belonging: v[2],
          locale: localeMap[v[3]],
          email: v[4],
          phone: v[5],
          normal_receive_method: normalReceiveMethodMap[v[6]],
          memo: v[7]
        };
      });
      createData("writer/bulk_write_participant", {
        participants: participants
      }).then(function () {
        _this3.$store.dispatch("commitModalMessage", {
          message: "関係者情報一括登録が完了しました",
          title: "登録完了"
        });

        _this3.showModal = true;
        _this3.isLoading = false;
      }).catch(function (error) {
        var message = "関係者情報一括登録に失敗しました";

        if (error.response) {
          if (error.response.status === 409) {
            message = "登録済みの電話番号が含まれています。";
          } else if (error.response.status === 401) {
            message = "重複した電話番号が送信されました";
          }
        }

        _this3.$store.dispatch("commitModalMessage", {
          message: message,
          title: "登録失敗"
        });

        _this3.showModal = true;
        _this3.isLoading = false;
      });
    },
    completeCreate: function completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "participants"
      });
    },
    open: function open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },
    close: function close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }
  }
};