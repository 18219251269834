import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import MessageModal from "../common/MessageModal.vue";
import { mapState } from "vuex";
import { createData, getData, updateData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../RowItem";
export default {
  name: "EditStudent",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      showModal: false,
      student: {},
      id: this.$route.params.id,
      new_id: false,
      isLoading: {
        getData: false,
        postData: false
      },
      loadingMessage: "",
      error: {
        grade_id: null,
        class_id: null,
        name: null,
        ruby: null
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["classrooms", "grades", "studentAddress"])), {}, {
    classroomList: function classroomList() {
      var _this = this;

      var newList = this.classrooms;

      if (this.student.grade_id) {
        var currentGrade = this.grades.filter(function (v) {
          return v.id === _this.student.grade_id;
        })[0];
        newList = newList.filter(function (v) {
          return currentGrade.classroom.find(function (c) {
            return c.id === v.id;
          });
        });
      }

      return newList;
    }
  }),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this2 = this;

      if (this.id) {
        this.isLoading.getData = true;
        getData("students/" + this.$route.params.id + "?with[]=guardian&with[]=classroom").then(function (data) {
          if (data) {
            _this2.student = data.items[0];

            if (_this2.student.guardian) {
              var paths = _this2.student.guardian.map(function (v) {
                return "guardians/" + v.id + "?with=user";
              });

              getData(paths).then(function (data) {
                if (data) {
                  _this2.student.guardian = data.map(function (v) {
                    var d = v.items[0]; //編集用にemailを1回層上げる

                    d.email = d.user.email;
                    return d;
                  });
                }
              });
            }
          }
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this2.isLoading.getData = false;
        });
      }
    },
    postStudent: function postStudent() {
      var _this3 = this;

      createData("writer/write_student", {
        student: this.student
      }).then(function (data) {
        _this3.new_id = data.message.id;

        _this3.$store.dispatch("commitModalMessage", {
          message: _this3.studentAddress + "情報登録が完了しました",
          title: "登録完了"
        });

        _this3.showModal = true;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this3.isLoading.postData = false;
      });
    },
    putStudent: function putStudent() {
      var _this4 = this;

      updateData("writer/write_student/" + this.id, {
        student: this.student
      }).then(function () {
        _this4.$store.dispatch("commitModalMessage", {
          message: _this4.studentAddress + "情報更新が完了しました",
          title: "更新完了"
        });

        _this4.showModal = true;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this4.isLoading.postData = false;
      });
    },
    saveData: function saveData() {
      var valid = true;
      this.error = {
        grade_id: null,
        class_id: null,
        name: null,
        ruby: null
      };

      if (!this.student.grade_id) {
        var message = "学年を指定してください。";
        this.error.grade_id = message;
        valid = false;
      }

      if (!this.student.class_id) {
        var _message = "クラスを入力してください。";
        this.error.class_id = _message;
        valid = false;
      }

      if (!this.student.name) {
        var _message2 = "名前を入力してください。";
        this.error.name = _message2;
        valid = false;
      } else {
        this.student.name = this.student.name.replace(/\s+/, " ");
      }

      if (!this.student.ruby) {
        var _message3 = "ふりがなを入力してください。";
        this.error.ruby = _message3;
        valid = false;
      } else {
        this.student.ruby = this.student.ruby.replace(/\s+/, " ");
      }

      if (!valid) {
        return;
      }

      this.isLoading.postData = true;

      if (this.id) {
        this.loadingMessage = "生徒情報を更新しています。";
        this.putStudent();
      } else {
        this.loadingMessage = "生徒情報を登録しています。";
        this.postStudent();
      }
    },
    completeCreate: function completeCreate() {
      this.showModal = false;

      if (this.new_id) {
        this.$router.push({
          name: "students"
        });
      } else {
        this.$router.push({
          name: "students.detail",
          params: {
            id: this.id
          }
        });
      }
    }
  }
};