import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import moment from "moment";
import { getData } from "@/services/axios";
import store from "../../services/store.js";
import PageSwitch from "./PageSwitch";
import RowItem from "../RowItem";
import { mapState } from "vuex";
export default {
  name: "EnqueteDetail",
  components: {
    RowItem: RowItem,
    PageSwitch: PageSwitch
  },
  store: store,
  data: function data() {
    return {
      enquete: {},
      isLoading: true,
      statuses: [],
      participantStatuses: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["studentAddress"])), {}, {
    isOpen: function isOpen() {
      return moment().isBefore(this.enquete.implement_to);
    }
  }),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData(["reader/get_enquete_detail/" + this.$route.params.id, "reader/get_enquete_statuses/" + this.$route.params.id]).then(function (data) {
        if (data) {
          _this.enquete = data[0].items;
          _this.statuses = data[1].items.students;
          _this.participantStatuses = data[1].items.participants;
        }
      }).catch(function (error) {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る

        if (error.response) {
          _this.$router.push({
            name: "enquetes"
          });
        }
      }).then(function () {
        _this.isLoading = false;
      });
    },
    getDateJP: function getDateJP(datetime) {
      return moment(datetime).format("YYYY年MM月DD日 HH:mm");
    }
  }
};