import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import { getData, createData } from "@/services/axios";
import { mapState } from "vuex";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../RowItem";
export default {
  name: "Assignment",
  components: {
    RowItem: RowItem,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      pageTitle: "学校管理 - クラス分け",
      otherClassPullDown: {
        grade: 0,
        class: 0
      },
      isLoading: {
        students: true,
        postData: false
      },
      currentGrade: {},
      currentGradeId: null,
      currentClassroomId: null,
      students: [],
      showModal: false,
      filterKeyword: "",
      filterNotSetOnly: false,
      filteredStudents: [],
      showSmallWindow: false
    };
  },
  head: {
    title: function title() {
      return {
        inner: this.pageTitle
      };
    },
    meta: []
  },
  computed: _objectSpread(_objectSpread({}, mapState(["school", "grades", "classrooms", "studentAddress"])), {}, {
    filteredNameList: function filteredNameList() {
      var _this = this;

      var students = this.filteredStudents;
      var keyword = this.filterKeyword;

      if (keyword) {
        students = students.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return String(v[k]).indexOf(_this.filterKeyword) > -1;
          });
        });
      }

      return students;
    },
    filteredClassrooms: function filteredClassrooms() {
      var _this2 = this;

      return this.classrooms.filter(function (v) {
        if (_this2.currentGrade && _this2.currentGrade.classroom) {
          return _this2.currentGrade.classroom.find(function (c) {
            return Number(c.id) === Number(v.id);
          });
        }
      });
    },
    classMemberCounts: function classMemberCounts() {
      var _this3 = this;

      var counts = [];
      this.classrooms.forEach(function (v) {
        var c = _this3.students.filter(function (s) {
          return s.class_id === v.id;
        });

        counts[v.id] = c.length;
      });
      return counts;
    },
    miniWinClassList: function miniWinClassList() {
      var _this4 = this;

      var newList = this.classrooms.filter(function (v) {
        return v.grade && v.grade[0].id === _this4.otherClassPullDown.grade;
      });

      if (newList && newList[0]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.otherClassPullDown.class = newList[0].id;
      }

      return newList;
    },
    notSetStudents: function notSetStudents() {
      return this.students.filter(function (s) {
        return !s.class_id;
      }).length;
    }
  }),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this5 = this;

      getData("students?with=classroom").then(function (data) {
        _this5.students = _this5.filteredStudents = data.items.map(function (v) {
          v.original_class_id = v.class_id;
          return v;
        }).sort(function (a, b) {
          return a.ruby < b.ruby ? -1 : a.ruby > b.ruby ? 1 : 0;
        });
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this5.isLoading.students = false;

        _this5.filterList();
      });
      this.currentGrade = this.grades.find(function (v) {
        return Number(v.id) === Number(_this5.$route.params.id);
      });
      this.currentGradeId = Number(this.$route.params.id);
    },
    filterList: function filterList() {
      var _this6 = this;

      var students = this.students;

      if (this.currentGradeId) {
        this.currentGrade = this.grades.find(function (v) {
          return v.id === _this6.currentGradeId;
        });
        students = students.filter(function (v) {
          return v.grade_id === Number(_this6.currentGradeId);
        });
      }

      if (this.currentClassroomId) {
        students = students.filter(function (v) {
          return v.original_class_id === Number(_this6.currentClassroomId);
        });
      }

      return this.filteredStudents = students;
    },
    resetFilter: function resetFilter() {
      this.filterKeyword = "";
      this.currentClassroomId = null;
      this.filterList();
    },
    openSmallWindow: function openSmallWindow(student_id) {
      // todo 学年一覧とクラス一覧を取得して１つ目のアイテムの値で初期化
      this.otherClassPullDown.class = 1;
      this.otherClassPullDown.grade = 1;
      this.showSmallWindow = student_id;
    },
    setOtherClass: function setOtherClass(student_id) {
      var index = this.students.findIndex(function (v) {
        return v.id === student_id;
      });
      this.students[index].grade_id = this.otherClassPullDown.grade;
      this.students[index].class_id = this.otherClassPullDown.class; // リスト再生成

      this.filterList();
      this.showSmallWindow = false;
    },
    saveAssignment: function saveAssignment() {
      var _this7 = this;

      this.isLoading.postData = true;
      createData("writer/write_assignment", {
        students: this.filteredStudents.map(function (v) {
          return {
            id: v.id,
            class_id: v.class_id
          };
        })
      }).then(function () {
        _this7.showModal = true;
        return getData("teachers/whoami");
      }).then(function (data) {
        _this7.$store.dispatch("commitSelfProfile", data.items[0]);
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this7.isLoading.postData = false;
      });
    }
  }
};