import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import moment from "moment";
import { createData, getData, updateData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import store from "../../services/store.js";
import LoadingMessage from "@/components/common/LoadingMessage";
import AttendanceSelection from "@/components/attendances/AttendanceSelection";
import { mapState } from "vuex";
import RowItem from "../RowItem";
export default {
  store: store,
  name: "CreateAttendance",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage,
    AttendanceSelection: AttendanceSelection
  },
  data: function data() {
    return {
      showModal: false,
      student: {
        classroom: {}
      },
      type: "",
      date: moment().format("YYYY-MM-DD"),
      isLoading: {
        getData: true,
        postData: false
      },
      guardian_memo: "",
      comment: "",
      validationErrors: {},
      error: null,
      attendanceType: null,
      countingType: null
    };
  },
  computed: _objectSpread({}, mapState(["studentAddress", "attendanceTypes", "attendanceBookCountingTypes"])),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      if (this.$route.name === "attendances.edit" && this.$route.params.id) {
        getData("attendances/" + this.$route.params.id).then(function (data) {
          var v = data.items[0];

          if (!v.is_daily_latest) {
            // 最新ではない欠席連絡を編集することはできない
            _this.$router.go(-1);
          }

          _this.guardian_memo = v.guardian_memo;
          _this.attendanceType = v.attendance_type_id;
          _this.countingType = v.counting_type_id;
          _this.comment = v.comment;
          _this.date = v.date;
          _this.type = v.type;
          return getData("students/" + v.student_id + "?with=classroom");
        }).then(function (data) {
          _this.student = data.items[0];
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this.isLoading.getData = false;
        });
      } else if (this.$route.query.student_id) {
        getData("students/" + this.$route.query.student_id + "?with=classroom").then(function (data) {
          _this.student = data.items[0];
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this.isLoading.getData = false;
        });
      }
    },
    setType: function setType(type) {
      var _this2 = this;

      this.type = type;
      var attendanceType = this.attendanceTypes.find(function (v) {
        return v.key === _this2.type;
      });

      if (attendanceType) {
        this.attendanceType = attendanceType.id;
        this.countingType = attendanceType.attendance_book_counting_type.id;
      } else {
        this.attendanceType = null;
        this.countingType = null;
      }
    },
    resetType: function resetType() {
      this.type = "";
    },
    saveData: function saveData() {
      var _this3 = this;

      var userInputs = {
        student_id: this.student.id,
        type: this.type,
        date: this.date,
        comment: this.comment,
        attendance_type_id: this.attendanceType,
        counting_type_id: this.countingType
      };

      if (this.validateInput(userInputs)) {
        this.isLoading.postData = true;

        if (this.$route.name === "attendances.edit" && this.$route.params.id) {
          updateData("attendances/" + this.$route.params.id, userInputs).then(function () {
            _this3.$store.dispatch("commitModalMessage", {
              message: "出欠更新が完了しました",
              title: "登録完了"
            });

            _this3.showModal = true;
          }).catch(function (error) {
            console.log(error);
          }).then(function () {
            _this3.isLoading.postData = false;
          });
        } else {
          createData("attendances", userInputs).then(function () {
            _this3.$store.dispatch("commitModalMessage", {
              message: "出欠登録が完了しました",
              title: "登録完了"
            });

            _this3.showModal = true;
          }).catch(function (error) {
            console.log(error);
          }).then(function () {
            _this3.isLoading.postData = false;
          });
        }
      }
    },
    validateInput: function validateInput(input) {
      this.validationErrors = {};

      if (!input.type) {
        this.validationErrors.type = "事由を決定してください";
      }

      return Object.keys(this.validationErrors).length === 0;
    },
    completeCreate: function completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "dashboard"
      });
    }
  }
};