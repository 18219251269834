import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import store from "../../services/store.js";
import { deleteData, getData, updateData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import { mapState } from "vuex";
import LoadingMessage from "@/components/common/LoadingMessage";
import ConfirmModal from "@/components/common/ConfirmModal";
import RowItem from "../RowItem";
export default {
  store: store,
  name: "AttendanceIndex",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage,
    ConfirmModal: ConfirmModal
  },
  data: function data() {
    return {
      showModal: false,
      student: {
        guardian: []
      },
      isLoading: {
        getData: true,
        handleData: false
      },
      loadingMessage: "",
      guardiansLimit: process.env.VUE_APP_GUARDIANS_LIMIT,
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: "",
      targetId: null,
      action: ""
    };
  },
  computed: _objectSpread({}, mapState(["studentAddress", "me", "capabilities"])),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("students/" + this.$route.params.id + "?with[]=guardian&with[]=classroom").then(function (data) {
        if (data) {
          _this.student = data.items[0];

          if (_this.student.guardian) {
            var paths = _this.student.guardian.map(function (v) {
              return "guardians/" + v.id + "?with=user";
            });

            getData(paths).then(function (data) {
              if (data) {
                _this.student.guardian = data.map(function (v) {
                  return v.items[0];
                });
              }
            });
          }
        }
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading.getData = false;
      });
    },
    labelLocale: function labelLocale(key) {
      var localeMap = {
        "ja-JP": "日本語",
        "en-US": "English",
        "es-US": "Español",
        "zh-CN": "中国語（簡体字）"
      };
      return localeMap[key];
    },
    confirmRemoveStudent: function confirmRemoveStudent(id) {
      this.action = "deleteStudent";
      this.targetId = id;
      this.confirmMessageTitle = this.studentAddress + "削除";
      this.confirmMessage = this.studentAddress + "情報を削除します。\n実行すると、登録されている保護者の情報も同時に削除されます。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },
    confirmRemoveGuardian: function confirmRemoveGuardian(id) {
      this.action = "deleteGuardian";
      this.targetId = id;
      this.confirmMessageTitle = "保護者情報削除";
      this.confirmMessage = "保護者情報を削除します。\nアンケート回答権を持つ保護者を削除した場合、他の保護者に自動的に引き継がれますが、別の保護者に引き継ぎたい場合は保護者編集から修正してください。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },
    confirmSwitchingPrimaryGuardian: function confirmSwitchingPrimaryGuardian(id) {
      this.action = "switchPrimaryGuardian";
      this.targetId = id;
      var target = this.student.guardian.find(function (v) {
        return v.id === id;
      });

      if (target) {
        this.confirmMessageTitle = "アンケート回答者変更";
        this.confirmMessage = target.name + "さんをアンケート回答者に指定します。\nそれ以外の保護者はアンケートに回答できなくなります。\n本当に実行してもよろしいですか？";
        this.showConfirmModal = true;
      }
    },
    handleData: function handleData() {
      var _this2 = this;

      this.isLoading.handleData = true;

      switch (this.action) {
        case "deleteStudent":
          this.loadingMessage = this.studentAddress + "情報を削除しています。";
          deleteData("eraser/delete_student/" + this.targetId).then(function () {
            _this2.$store.dispatch("commitModalMessage", {
              message: _this2.studentAddress + "情報が削除されました",
              title: "削除完了"
            });

            _this2.showModal = true;
          }).catch(function (error) {
            console.log(error);
          }).then(function () {
            _this2.isLoading.handleData = false;
            _this2.showConfirmModal = false;
          });
          break;

        case "deleteGuardian":
          this.loadingMessage = "保護者情報を削除しています。";
          deleteData("eraser/delete_guardian/" + this.targetId + "?student_id=" + this.student.id).then(function () {
            _this2.$store.dispatch("commitModalMessage", {
              message: "保護者情報が削除されました",
              title: "削除完了"
            });

            _this2.showModal = true;
          }).catch(function (error) {
            console.log(error);
          }).then(function () {
            _this2.isLoading.handleData = false;
            _this2.showConfirmModal = false;
          });
          break;

        case "switchPrimaryGuardian":
          {
            var guardian = this.student.guardian.find(function (v) {
              return v.id === _this2.targetId;
            });
            guardian.email = guardian.user.email;
            guardian.role_id = 21;
            guardian.target_student_id = this.student.id;
            this.loadingMessage = "アンケート回答者を変更しています。";
            updateData("writer/write_guardian/" + guardian.id, {
              guardian: guardian
            }).then(function () {
              _this2.$store.dispatch("commitModalMessage", {
                message: "アンケート回答者の変更が完了しました",
                title: "変更完了"
              });

              _this2.showModal = true;
            }).catch(function (error) {
              console.log(error);
            }).then(function () {
              _this2.isLoading.handleData = false;
              _this2.showConfirmModal = false;
            });
          }
      }
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    },
    closeModal: function closeModal() {
      switch (this.action) {
        case "deleteStudent":
          this.$router.push({
            name: "students"
          });
          break;

        case "deleteGuardian":
        case "switchPrimaryGuardian":
          this.isLoading.getData = true;
          this.setData();
          break;
      }

      this.showModal = false;
    }
  }
};