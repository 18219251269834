import "core-js/modules/es.array.push.js";
import moment from "moment";
import MonthlyTableHead from "../monthly/MonthlyTableHead.vue";
import MonthlySchoolDay from "../monthly/MonthlySchoolDay.vue";
import MonthDailyEvent from "../monthly/MonthDailyEvent.vue";
import DailySummaryTable from "../monthly/DailySummaryTable.vue";
import MonthlyStudentTable from "../monthly/MonthlyStudentTable.vue";
import { showSumHoursAndMinutes } from "@/services/common";

import { ref, computed } from 'vue';
import { useMonthlyYearlySummary } from '/home/soutaito/projects/cocoo-137/cocoo_school_admin/src/composables/monthlyYearlySummary.js';
import { useDownloadAttendanceBook } from '/home/soutaito/projects/cocoo-137/cocoo_school_admin/src/composables/downloadAttendanceBook.js';
export default {
  __name: 'MonthlyClassTable',
  props: {
    classIndex: {
      type: Number,
      required: true
    },
    monthData: {
      /** @type {PropType<MonthData[]>} */
      type: Array,
      required: true
    },
    dailyEvents: {
      type: Object,
      required: true
    },
    studentAttendaceList: {
      type: Array,
      required: true
    },
    filter: {
      /** @type {PropType<AttendanceFilter>} */
      type: Object,
      required: true
    },
    classroom: {
      type: Object,
      required: true
    },
    displayLabel: {
      type: String,
      default: ""
    }
  },
  emits: ["handleCommentEdit"],

  setup(__props, {
    expose,
    emit
  }) {
    const props = __props; // @ts-check

    const dailySummary = ref(new Map([["日々出席人数", props.monthData.map(() => props.studentAttendaceList.length)], ["日々欠席(病気)", props.monthData.map(() => 0)], ["日々欠席(事故)", props.monthData.map(() => 0)], ["停忌等", props.monthData.map(() => 0)]]));
    const {
      schoolDayLength,
      getSummaryTemplate,
      aggregateStudentSummary
    } = useMonthlyYearlySummary(props.monthData);
    const schoolDayBeforeYearMonthLength = computed(() => {
      const yearMonth = moment(props.filter.yearMonth).add(1, "month").startOf("month");
      const today = moment();

      if (today.isBefore(yearMonth)) {
        return props.monthData.filter(v => !v.isSchoolDayOff && today.isAfter(v["YYYY-MM-DD"])).length;
      } else {
        return props.monthData.filter(v => !v.isSchoolDayOff && yearMonth.isAfter(v["YYYY-MM-DD"])).length;
      }
    });
    const students = computed(() => props.studentAttendaceList.map(student => {
      const monthlySummary = getSummaryTemplate();
      monthlySummary["要出席日数"] = schoolDayLength.value;
      monthlySummary["出席日数"] = schoolDayBeforeYearMonthLength.value;
      const attendanceByDate = {};
      const dekitusByDate = {}; // @ts-expect-error

      student.attendance.forEach(v => {
        const dateNumber = moment(v.date).date() - 1;

        if (props.monthData[dateNumber].isSchoolDayOff) {
          return; // 休日のデータは無視する
        } // カレンダーの表示 (countingTypeはなくても表示する)


        attendanceByDate[v.date] = v;

        if (!v.counting_type) {
          return; // countingTypeがないときはSummaryに含めない
        }

        const countingType = v.counting_type.counting_type; // month

        aggregateStudentSummary(monthlySummary, countingType); // 1日ごとのクラス別合計

        if (countingType === "病気欠席") {
          dailySummary.value.get("日々欠席(病気)")[dateNumber]++;
          dailySummary.value.get("日々出席人数")[dateNumber]--;
        } else if (countingType === "事故欠席") {
          dailySummary.value.get("日々欠席(事故)")[dateNumber]++;
          dailySummary.value.get("日々出席人数")[dateNumber]--;
        } else if (countingType === "停忌等") {
          dailySummary.value.get("停忌等")[dateNumber]++;
          dailySummary.value.get("日々出席人数")[dateNumber]--;
        }
      }); // @ts-expect-error

      let dekitusByMonth = [];
      student.dekitus.forEach(v => {
        if (dekitusByDate[v.start_date]) {
          dekitusByDate[v.start_date].push(v.time);
        } else {
          dekitusByDate[v.start_date] = [v.time];
        }

        dekitusByMonth.push(v.time);
      });
      Object.keys(dekitusByDate).forEach(k => {
        const sum = showSumHoursAndMinutes(dekitusByDate[k], true);
        dekitusByDate[k] = sum;
      });

      if (dekitusByMonth.length > 0) {
        dekitusByMonth = showSumHoursAndMinutes(dekitusByMonth, true);
      } else {
        dekitusByMonth = "";
      }

      return Object.assign(student, {
        attendanceByDate,
        monthlySummary,
        dekitusByDate,
        dekitusByMonth
      });
    }));
    const {
      downloadAttendanceBook
    } = useDownloadAttendanceBook(props.filter, props.monthData, dailySummary.value, props.dailyEvents, students.value, props.classroom);

    const handleCommentEdit = v => {
      emit("handleCommentEdit", v);
    };

    expose({
      downloadAttendanceBook
    });
    return {
      __sfc: true,
      props,
      emit,
      dailySummary,
      schoolDayLength,
      getSummaryTemplate,
      aggregateStudentSummary,
      schoolDayBeforeYearMonthLength,
      students,
      downloadAttendanceBook,
      handleCommentEdit,
      MonthlyTableHead,
      MonthlySchoolDay,
      MonthDailyEvent,
      DailySummaryTable,
      MonthlyStudentTable
    };
  }

};