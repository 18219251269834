import _objectSpread from "/home/soutaito/projects/cocoo-137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { login } from "@/services/auth";
import { mapState } from "vuex";
export default {
  name: "Index",
  data: function data() {
    return {
      isForceLogout: false
    };
  },
  head: {
    title: function title() {
      return {
        inner: "ログイン"
      };
    },
    meta: []
  },
  computed: _objectSpread(_objectSpread({}, mapState(["news"])), {}, {
    ttlJP: function ttlJP() {
      var ttl = 1000 * 60 * 60;
      var ttlJP = "一定時間";

      if (ttl) {
        ttl = ttl / 1000 / 60;
        ttlJP = ttl + "分";
      }

      return ttlJP;
    }
  }),
  created: function created() {
    this.isForceLogout = !!localStorage.getItem("FORCE_LOGOUT");
  },
  methods: {
    handleLogin: function handleLogin() {
      login();
    }
  }
};